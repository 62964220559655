export const getAnalyticModels = async (siteId) => {
  let result = null;
  try {
    result = await window.axios.get('/v1/analysismodel/sites/' + siteId);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const validateAnalyticModelNameForCreate = async (siteId, name) => {
  let result = null;
  let model = {
    siteId: siteId,
    name: name,
  }
  try {
    result = await window.axios.post('/v1/analysismodel/create/validatename/', model);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const validateAnalyticModelNameForUpdate = async (siteId, id, name) => {
  let result = null;
  let model = {
    id: id,
    name: name,
    siteId: siteId
  }
  try {
    result = await window.axios.post('/v1/analysismodel/update/validatename/', model);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const validateAnalyticModelDataForRange = async (siteId, startTime, endTime) => {
  console.log(startTime + endTime + siteId)
  try {
    let result = await window.axios.get(`/v1/analysismodel/energy/weathernormalized/validate/?siteid=${siteId}&starttime=${startTime}&endtime=${endTime}`);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }



};

export const createAnalyticModel = async (siteId, model) => {
  let result = null;
  try {
    result = await window.axios.post("/v1/analysismodel/sites/" + siteId, model);
    return result.data.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getPortfolioAnalyticModels = async () => {
  let result = null;
  try {
    result = await window.axios.get('/v1/analysismodel/Portfolio');
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAnalyticProjects = async (siteId) => {
  let result = null;
  try {
    result = await window.axios.get('/v1/analyticsproject/sites/' + siteId);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAnalyticProject = async (projectId) => {
  let result = null;
  try {
    result = await window.axios.get('/v1/analyticsproject/' + projectId);
    let project = result.data.data;
    return project;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getAnalyticModel = async (modelId) => {
  let result = null;
  try {
    result = await window.axios.get('/v1/analysismodel/' + modelId);
    let model = result.data.data;
    return model;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

//Analytics Service start
export const downLoadAnalyticModelZip = async (siteId, analyticModel) => {
  let result = null;
  const requestOptions = { responseType: 'blob', data: analyticModel, method: 'post', url: "/v1/analytics/energy/sites/" + siteId + "/weathernormalized/predictions/download" }

  try {
    result = await window.axios2.request(requestOptions);
    let zip = result.data;
    return zip;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const downLoadAnalyticModelPDF = async (siteId, analyticModel) => {
  let result = null;
  const requestOptions = { responseType: 'blob', data: analyticModel, method: 'post', url: "/v1/analytics/energy/sites/" + siteId + "/weathernormalized/predictions/report" }

  try {
    result = await window.axios2.request(requestOptions);
    let zip = result.data;
    return zip;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export const analyzeAnalyticModel = async (siteId, analyticModel) => {
  let result = null;
  try {
    result = await window.axios2.post("/v1/analytics/energy/sites/" + siteId + "/weathernormalized/predictions", analyticModel);
    let predictions = result.data.data;
    return predictions;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const analyzeEnergySavings = async (siteId, analyticModel, projectStart, projectEnd, implementationEnd) => {
  let result = null;
  try {
    result = await window.axios2.post("/v1/analytics/energy/sites/" + siteId + "/weathernormalized/savings?start=" + projectStart + "&end=" + projectEnd + "&projectEnd=" + implementationEnd, analyticModel);
    let predictions = result.data.data;
    return predictions;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};
//Analytics Service end

export const getSites = async () => {
  let result = null
  try {
    result = await window.axios.get('/v1/sites/portfolio')
    let sites = result.data.data;
    return sites;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
}

export const getMeasures = async () => {
  let result = null
  try {
    result = await window.axios.get('/v1/measure')
    let measures = result.data.data;
    return measures;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
}

export const getMeasureById = async (id) => {
  let result = null
  try {
    result = await window.axios.get('/v1/measure/' + id)
    let measure = result.data.data;
    return measure;
  } catch (err) {
    console.error(err);
    return Promise.reject(err)
  }
}

export const addMeasure = async (measure) => {
  let result = null;
  try {
    result = await window.axios.post("/v1/measure", measure);
    let newMeasure = result.data.data;
    return newMeasure;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const updateMeasure = async (measure) => {
  let result = null;
  try {
    result = await window.axios.put("/v1/measure/update", measure);
    let newMeasure = result.data.data;
    return newMeasure;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const deleteMeasure = async (measure) => {
  let result = null;
  var config = {
    data: {
      measureId: measure.id,
    }
  };
  try {
    result = await window.axios.delete("/v1/measure/delete", config);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getMeasureTypes = async () => {
  let result = null;
  try {
    result = await window.axios.get("/v1/measure/measuretypes");
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getMeasureInstallTypes = async () => {
  let result = null;
  try {
    result = await window.axios.get("/v1/measure/installtypes");
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export const getMeasureMarketSectors = async () => {
  let result = null;
  try {
    result = await window.axios.get("/v1/measure/marketsectors");
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};

export default {
  getSites,
  getAnalyticProject,
  getAnalyticModels,
  getAnalyticModel,
  createAnalyticModel,
  validateAnalyticModelNameForCreate,
  validateAnalyticModelNameForUpdate,
  validateAnalyticModelDataForRange,
  getAnalyticProjects,
  getPortfolioAnalyticModels,
  analyzeAnalyticModel,
  analyzeEnergySavings,
  downLoadAnalyticModelZip,
  downLoadAnalyticModelPDF,

  getMeasures,
  getMeasureById,
  addMeasure,
  updateMeasure,
  deleteMeasure,
  getMeasureTypes,
  getMeasureInstallTypes,
  getMeasureMarketSectors
}